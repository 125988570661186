import React, { useEffect } from 'react';
import { getConversationAction } from '../../store/actions/common';
import Libersift from '../../components/libersiftScreen/startSection';
import Reqvise from '../../components/reqviseScreen/startSection';
import Footer from '../../components/Layout/Footer/footer';
import { useSelector } from 'react-redux';
const Home = ({ type }) => {
  const { conversations } = useSelector((state) => state);
  useEffect(() => {
    getConversationAction(2);
  }, []);
  return (
    <div>
      {type === 'libersift' ? <Libersift /> : <Reqvise />}
      <Footer />
    </div>
  );
};

export default Home;
