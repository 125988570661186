import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Login from "../common/Login";
import AiBG from "../../assets/images/Ai-bg.png";
import LibersiftLogo from "../../assets/images/libersift-logo.svg";
import ReqvuiseLogo from "../../assets/images/reqvuise-logo.svg";
import AmazitLogo from "../../assets/images/amazit.svg";
import Ai4Process from "../../assets/images/ai4process_logo.svg";
import Revqvise from "../../assets/images/reqviseLogo.svg";
import Libersift from "../../assets/images/libersift-logo.svg";
import Footer from "../Layout/Footer/footer";
import { ActiveModule } from "../../store/reducers/common";

const Index = () => {
  const [select, setSelect] = useState(false);
  const dispatch = useDispatch();

  return !select ? (
    <div
      className="w-full min-h-screen pt-[94px] pb-[150px]  relative px-5"
      style={{
        backgroundImage: `url(${AiBG})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-[913px] h-full  mx-auto text-[#fff]">
        <div className="w-full mb-10 text-center md:mb-4">
          <p className="text-[20px] font-light leading-normal">Welcome to</p>
          {/* <h1 className="text-[45px] font-bold leading-normal mb-[58px]">
            Ai4Process
          </h1> */}
          <img
            src={Ai4Process}
            alt="Ai4Process"
            className="w-fit h-auto object-contain mx-auto mt-5 mb-[58px]"
          />
          <p className="text-[20px] font-light leading-normal">
            What do you want to work with today?
          </p>
        </div>

        <div className="flex flex-col items-center justify-between w-full gap-10 md:flex-row md:gap-6 ">
          <div
            onClick={() => {
              setSelect("reqvise");
              localStorage.setItem("module", "reqvise");
              dispatch(ActiveModule("reqvise"));
            }}
            className="max-w-[325px] cursor-pointer"
          >
            <img
              src={ReqvuiseLogo}
              alt="Libersift"
              className="w-fit h-auto object-contain mx-auto mb-[35px]"
            />
            <p className="text-[20px] font-light leading-normal">
              Reqvise is a tool that uses natural language processing to help
              system requirement engineers write requirements that are clear,
              consistent, and can be tested.
            </p>
          </div>
          <div className="w-full md:w-1 h-1 md:h-[383px] bg-[#fff]" />
          <div
            onClick={() => {
              setSelect("libersift");
              localStorage.setItem("module", "libersift");
              dispatch(ActiveModule("libersift"));
            }}
            className="max-w-[368px] cursor-pointer"
          >
            <img
              src={LibersiftLogo}
              alt="Libersift"
              className="w-fit h-auto object-contain mx-auto mb-[35px]"
            />
            <p className="text-[20px] font-light leading-normal">
              Libersift is AI-powered search tool that revolutionizes the way
              you navigate technical documentation. Libersift leverages advanced
              AI algorithms to deliver fast and accurate answers to your
              queries.
            </p>
          </div>
        </div>
      </div>

      <div className="absolute bottom-7 md:bottom-[59px] right-[48px]">
        <img src={AmazitLogo} alt="Amazit" className="w-fit h-fit " />
      </div>
    </div>
  ) : (
    <div className="flex flex-col h-screen">
      <Login
        type={select}
        setSelect={setSelect}
        logo={select !== "libersift" ? Revqvise : Libersift}
        text={
          select !== "libersift"
            ? "Reqvise is a tool that uses natural language processing to help system requirement engineers write requirements that are clear, consistent, and can be tested."
            : "Libersift is AI-powered search tool that revolutionizes the way you navigate technical documentation. Libersift leverages advanced AI algorithms to deliver fast and accurate answers to your queries."
        }
        onLogin={select}
      />
      <Footer />
    </div>
  );
};

export default Index;
