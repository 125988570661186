export const parameters = [
  {
    type: "Grammar",
    values: [
      {
        name: "Correction",
        id: 1,
      },
      { name: "No Correction", id: 2 },
    ],
  },
  {
    type: "Vocabulary",
    values: [
      { name: "Improve writing", id: 3 },
      { name: "Paraphrase", id: 4 },
      { name: "Easy Technical formulation Tech", id: 5 },
      { name: "No Edits", id: 6 },
    ],
  },
  {
    type: "Technical Level",
    values: [
      { name: "Testable", id: 7 },
      { name: "unambiguous", id: 8 },
      { name: "abstract", id: 9 },
      { name: "No Edits", id: 10 },
    ],
  },
];
