import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion } from 'flowbite-react';
import { getChatByIdAction } from '../../../store/actions/common';
import { ActiveConversation } from '../../../store/reducers/common';
import { useDispatch } from 'react-redux';
import Button from '../../../utils/Button';
const Index = ({ search, docsOnly, setStart, setShowHistory, start }) => {
  const [active, setActive] = useState('');
  const {
    conversations: { allConversations, ActiveModule },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = async (data) => {
    setActive(data);
    const result = await getChatByIdAction(data.id);
    dispatch(ActiveConversation(data));
    setStart(true);
    setShowHistory(true);
  };
  return (
    <>
      {start && (
        <div className="flex justify-end mb-[10px]">
          <Button
            text={'Start Conversation'}
            color="text-Black-700"
            cta={() => {
              // setInput([]);
              // setOutput([]);
              setStart(false);
            }}
            className={`class="rounded-lg fit-content flex h-[53px] fit-content items-center font-light text-[22px] justify-center leading-8 bg-Gray-100  text-Black px-[16px] py-2 bg-Mist-600 text-Black-800 rounded-[10px] px-[10px] flex-shrink-0 flex justify-center p-small !font-normal Atlata"
          `}
          />
        </div>
      )}
      {docsOnly ? (
        <div className="border-t-[#08090c] border-x-[#08090c]  !border-b border-b-[#45D9FC] rounded-none">
          {allConversations &&
            allConversations
              ?.filter((data) => data.parent === ActiveModule && data.filePath)
              ?.map((data) => {
                return (
                  <div className="w-full ">
                    <a
                      href={`${process.env.REACT_APP_API_URL}/uploads/${data.download}`}
                      download
                      target="_blank"
                      className="h6 !leading-[46px] block  py-[10px] text-White-200 border-t border-solid border-[#45D9FC] break-words"
                      rel="noreferrer"
                    >
                      {data.filePath}
                    </a>
                  </div>
                );
              })}
        </div>
      ) : (
        <div className="w-full max-h-[565px] min-h-fit h-auto overflow-y-scroll">
          <Accordion className="border-t-[#08090c] border-x-[#08090c]  !border-b border-b-[#45D9FC] rounded-none">
            {allConversations &&
              allConversations
                ?.filter(
                  (data) =>
                    data.parent === ActiveModule &&
                    data.title.toLowerCase()?.includes(search?.toLowerCase())
                )
                ?.map((data, counter) => {
                  return (
                    <div className="w-full ">
                      <div
                        className={`h6 !leading-[46px] py-[10px] text-White-200 border-t border-solid border-[#45D9FC]   ${
                          active?.id === data.id
                            ? 'bg-[#45d9fc] text-[#000] pl-[10px] cursor-pointer'
                            : counter === 0 && !active?.id && start
                            ? 'bg-[#45d9fc] text-[#000] pl-[10px] cursor-pointer '
                            : 'pl-[10px] text-[#fff] cursor-pointer'
                        }`}
                      >
                        <div onClick={() => history(data)}> {data.title} </div>
                      </div>
                    </div>
                  );
                })}
          </Accordion>
        </div>
      )}
    </>
  );
};

export default Index;
