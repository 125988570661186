import {
  addConversation,
  getConversation,
  addChat,
  getChatById,

} from '../../service/common';
import { store } from '../';
import { allConversations, AllChatsHistory } from '../reducers/common';
const createConversation = async (data) => {
  const result = await addConversation(data);

  return result;
};

const getConversationAction = async (data) => {
  const result = await getConversation(data);
  if (result.status === 200) {
    store.dispatch(allConversations(result?.data));
  }
  return result;
};

const addChatAction = async (data) => {
  const result = await addChat(data);
  if (result.status === 200) {
    //  store.dispatch(allConversations(result?.data));
  }
  return result;
};

const getChatByIdAction = async (data) => {
  const result = await getChatById(data);
  if (result.status === 200) {
     store.dispatch(AllChatsHistory(result?.data));
  }
  return result;
};

export { createConversation, getConversationAction, addChatAction, getChatByIdAction };
