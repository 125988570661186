import { postAI } from "./http";

const uploadDocs = async (data) => {
  try {
    return await postAI(`/uploadUserDocsAPI`, data, {
      'content-Type': 'multipart/form-data',
    });
  } catch (err) {
    return err;
  }
};
const chatDocsApi = async (data) => {
  // alert()
  // try {
    return await postAI(`/chatWithDocsAPI`, data);
  // } catch (err) {
  //   return err;
  // }
};

export { uploadDocs, chatDocsApi };
