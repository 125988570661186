import { post, get } from './http';

const addConversation = async (data) => {
  try {
    return await post(`/conversations`, data, {
      'content-Type': 'multipart/form-data',
    });
  } catch (err) {
    return err;
  }
};


const addChat = async (data) => {
  try {
    return await post(`/chat`, data );
  } catch (err) {
    return err;
  }
};


const getChatById = async (data) => {
  try {
    return await get(`/chat/${data}` );
  } catch (err) {
    return err;
  }
};


const getConversation = async (data) => {
  try {
    return await get(`/conversations/${data}`, );
  } catch (err) {
    return err;
  }
};

export { addConversation, getConversation, addChat, getChatById };
