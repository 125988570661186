import React, { useEffect, useState } from "react";
import FileIcon from "../../../assets/images/fileIcon.svg";
import RightArrowIcon from "../../../assets/images/rightArrowIcon.svg";
import Button from "../../../utils/Button";
import { chatDocsAction } from "../../../store/actions/libreSiftAction";
import { runRevisionAction } from "../../../store/actions/reqViseAction";
import { addChatAction } from "../../../store/actions/common";
import { parameters } from "../../../dummyData";
import { useSelector } from "react-redux";

const NatualLanguage = ({ document, showHistory, setStart, start }) => {
  const [selectedLabel, setSelectedLabel] = useState(2);
  const [selectedVocabulary, setSelectedVocabulary] = useState(6);
  const [selectedTechnicalLevel, setSelectedTechnicalLevel] = useState(10);

  const [input, setInput] = useState([]);
  const [output, setOutput] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentText, setCurrentText] = useState();
  const { conversations } = useSelector((state) => state);

  const userData = localStorage.getItem("user");

  console.log(userData);
  const handleButtonClick = (type, id) => {
    switch (type) {
      case "Grammar":
        setSelectedLabel(id);
        break;
      case "Vocabulary":
        setSelectedVocabulary(id);
        break;
      case "Technical Level":
        setSelectedTechnicalLevel(id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // if (selectedLabel.length === 0 && parameters.length > 0) {
    //   setSelectedLabel([parameters[0]?.values?.id]);
    // }
    setInput([])
    setOutput([])
  }, [start]);

  useEffect(() => {
    setInput([]);
    setOutput([]);
  }, [start, showHistory, document]);
  useEffect(() => {
    if (output.length) {
      addChatAction({
        title: output[output.length - 1].text,
        conversation_id: conversations.ActiveConversation?.id,
        type: "output",
      });
    }
  }, [output]);

  useEffect(() => {
    if (conversations.AllChatsHistory?.length) {
      setInput(
        conversations.AllChatsHistory?.filter((data) => data.type === "input"),
      );
      setOutput(
        conversations.AllChatsHistory?.filter((data) => data.type === "output"),
      );
    }
  }, [conversations.AllChatsHistory]);

  useEffect(() => {
    if (input.length) {
      addChatAction({
        title: input[input.length - 1].text,
        conversation_id: conversations.ActiveConversation?.id,
        type: "input",
      });
    }
  }, [input]);
  const SubmitReq = async (e) => {
    if (e.key === "Enter") {
      setInput([...input, { text: e.target.value, time: Date.now() }]);
      setCurrentText("");
      setLoader(true);

      if (document) {
        const data = {
          username: "john",
          input_msg: e.target.value,
        };
        const result = await chatDocsAction(data);
        setLoader(false);
        console.log(result);
        if (result.data) {
          setOutput([
            ...output,
            { text: result.data?.["Generated Text"], time: Date.now() },
          ]);
        }
      } else {
        const data = {
          input_req: e.target.value,
          optimize_lst: [
            parameters.filter(data=>data.type==='Grammar')[0].values?.filter(val=>val.id===selectedLabel)[0]?.name,
            parameters.filter(data=>data.type==='Vocabulary')[0].values?.filter(val=>val.id===selectedVocabulary)[0]?.name,
            parameters.filter(data=>data.type==='Technical Level')[0].values?.filter(val=>val.id===selectedTechnicalLevel)[0]?.name,
            
          ],
          requirements_type: "marketing",
        };
        const result = await runRevisionAction(data);
        setLoader(false);
        console.log(result);
        if (result.data) {
          setOutput([
            ...output,
            { text: result.data?.["Generated Text"], time: Date.now() },
          ]);
        }
      }
    }
  };
  return (
    <>
      <div className="w-full min-h-[552px] flex gap-[30px] mb-[31px]">
        <div className="flex w-full flex-col  justify-between gap-[106px] mt-[13px]">
          <div className="w-full">
            {document ? (
              <div className="flex flex-row items-center gap-[14px] justify-center mb-[45px]">
                <img src={FileIcon} alt="FileIcon" />
                <p className="break-words text-White h4">
                  {showHistory
                    ? conversations.ActiveConversation?.filePath
                    : conversations.ActiveDoc}
                </p>
              </div>
            ) : (
              !showHistory && (
                <h5 className=" h5 text-center text-Grey-400 !leading-normal !tracking-[-1.1px] mb-[9px]">
                  Write down your requirement and chose how you want Reqvise to
                  transform it
                </h5>
              )
            )}
            {input?.map((inp, counter) => {
              return (
                <>
                  <p className="p-large  !tracking-[-0.2px] text-primaryWhite pl-[15px] mb-[10px]">
                    <span className="!font-black">
                      {userData?.split("@")?.[0] || userData}:
                    </span>
                    &nbsp; &nbsp;{inp.text || inp.title}
                  </p>
                  {(output[counter]?.text || output[counter]?.title) && (
                    <p className="p-large !font-medium !tracking-[-0.2px] text-primaryWhite pl-[15px] mb-[10px]">
                      <span className="!font-black">
                        {conversations.ActiveModule}:{" "}
                      </span>
                      {output[counter]?.text || output[counter]?.title}
                    </p>
                  )}
                </>
              );
            })}
            {loader && (
              <p className="p-large italic !font-medium !tracking-[-0.2px] text-primaryWhite pl-[15px] mb-[10px]">
                Typing ...
              </p>
            )}
          </div>
          {!showHistory && (
            <div className="flex flex-row bg-Grey-800 min-h-[45px] mb-[57px] ">
              <input
                type="text"
                placeholder=""
                className="!bg-Grey-800 border-none text-White focus:outline-none focus:ring-0 w-full"
                value={currentText}
                onChange={(e) => {
                  setCurrentText(e.target.value);
                }}
                disabled={loader}
                onKeyDown={SubmitReq}
              />
              <img
                onClick={() => SubmitReq(currentText)}
                src={RightArrowIcon}
                alt="arrow"
                className="mr-[15px]"
              />
            </div>
          )}
          {/* <button>sumbit</button> */}
        </div>
        {!document && !showHistory && (
          <div className="gap-4 md:flex w-fit">
            <div className="w-[1px] h-full bg-White-500"></div>
            <div className="flex flex-col justify-between w-min">
              <div>
                {parameters.map((item, index) => (
                  <div key={index} className="mb-[14px]">
                    <p className="p-small !leading-normal text-White-600 mb-2">
                      {item.type}
                    </p>
                    <div className="grid grid-cols-1 gap-y-[7px] mb-[7px]">
                      {item?.values.map((val, i) => (
                        <Button
                          key={i}
                          text={val.name}
                          color="text-Black-700"
                          cta={() => handleButtonClick(item.type, val.id)}
                          className={`rounded-[5px] text-[#fff] p-xlarge hover:font-medium min-w-[86px] bg-Grey-700 !min-h-6 !h-auto border-none  ${
                            selectedLabel === val.id ||
                            selectedVocabulary === val.id ||
                            selectedTechnicalLevel === val.id
                              ? "!font-medium text-[#000] bg-Mist-500"
                              : ""
                          } `}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-center underline p-xlarge text-White-900 whitespace-nowrap ">
                Use the same parameters always
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NatualLanguage;
