import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allConversations: null,
  ActiveModule: "",
  ActiveDoc: "",
  ActiveConversation: "",
  AllChatsHistory: null,
};

export const conversations = createSlice({
  name: "conservations",
  initialState,
  reducers: {
    allConversations: (state, action) => {
      state.allConversations = action.payload;
    },
    ActiveModule: (state, action) => {
      state.ActiveModule = action.payload;
    },
    activeDoc: (state, action) => {
      state.ActiveDoc = action.payload;
    },
    ActiveConversation : (state, action) => {
      state.ActiveConversation = action.payload;
    },
    AllChatsHistory  : (state, action) => {
      state.AllChatsHistory = action.payload;
    }
  },
});

export const { allConversations, ActiveModule, activeDoc, ActiveConversation, AllChatsHistory } = conversations.actions;

export default conversations.reducer;
