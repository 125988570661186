import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/home';
import Login from '../components/welComeScreen';
import { useDispatch } from 'react-redux';
import { ActiveModule } from '../store/reducers/common';
const AppRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('module')) {
      dispatch(ActiveModule(localStorage.getItem('module')));
    } else {
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reqvise" element={<Home type="reqvise" />} />
      <Route path="/libersift" element={<Home type="libersift" />} />
    </Routes>
  );
};

export default AppRouter;
