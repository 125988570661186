import React, { useState, useRef } from "react";
import { addConversation } from "../../../service/common";
import { Button } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import SupportIcon from "../../../assets/images/icons/support.svg";
import { uploadDocsAction } from "../../../store/actions/libreSiftAction";
import { getConversationAction } from "../../../store/actions/common";
import { activeDoc, ActiveConversation } from "../../../store/reducers/common";
const Index = ({ document, startConversation }) => {
  const [fileContent, setFileContent] = useState(null);
  const ref = useRef();
  const state = useSelector((state) => state);
  const [name, setName] = useState();
  const [doc, setDoc] = useState();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length === 1) {
      const file = droppedFiles[0];

      if (file.type === "text/plain") {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFileContent(e.target.result);
        };
        reader.readAsText(file);
      } else if (file.type === "application/pdf") {
        setFileContent(
          <iframe
            src={URL.createObjectURL(file)}
            width="100%"
            height="500px"
            title="PDF Viewer"
          ></iframe>,
        );
      } else if (
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setFileContent(
          <a href={URL.createObjectURL(file)} download={file.name}>
            Download {file.name}
          </a>,
        );
      } else {
        setFileContent("Unsupported file type");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  console.log("fileContent", fileContent);

  const create = async () => {
    if (state.conversations?.ActiveModule === "libersift" && (!name || !doc)) {
      return;
    } else if (state.conversations?.ActiveModule === "reqvise" && !name) {
      return;
    } else {
      setLoader(true);
      let AIresult;
      if (state.conversations?.ActiveModule === "libersift") {
        const formDataApi = new FormData();
        formDataApi.append("files", doc);
        AIresult = await uploadDocsAction(formDataApi);
      }
      console.log(AIresult);
      if (
        AIresult?.status === 200 ||
        state.conversations?.ActiveModule === "reqvise"
      ) {
        const formData = new FormData();
        formData.append("file", doc || "");
        formData.append("parent", state.conversations?.ActiveModule);
        formData.append("userId", "2");
        formData.append("title", name);

        console.log(formData);
        const result = await addConversation(formData);
        setLoader(false);

        if (result?.status === 201) {
          startConversation(true);
          getConversationAction(2);
          if (doc) {
            dispatch(activeDoc(doc.name));
          }
          dispatch(ActiveConversation(result.data));
        }
      }
    }
  };

  const uplaodDoc = async (e) => {
    console.log(e);
    if (
      ["pdf", "doc", "docs", "text", "txt"].includes(
        e.target.files[0]?.type?.split("/")?.[1],
      )
    ) {
      setDoc(e.target.files[0]);
    } else {
      alert("invalid file selected");
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="flex flex-col items-center text-Grey-400 mt-[110px] mb-[150px]"
    >
      {document && (
        <div
          onClick={() => {
            ref.current?.click();
          }}
        >
          <h3 className="h3 max-w-[576px] mb-[30px] text-center">
            Drag & drop one or more documents and let Libersift do its magic.
          </h3>
          <img
            src={SupportIcon}
            alt="supportIcon"
            className="w-[53px] h-[53px] mb-[15px] mx-auto"
          />
          <input
            type="file"
            className="hidden"
            onChange={uplaodDoc}
            ref={ref}
          />
          <p className="p-xlarge !text-[12.555px] mb-[43.33px] text-center">
            Supported files: PDF, TXT, DOC, <br /> DOCX
            <strong>{doc && doc?.name}</strong>
          </p>
        </div>
      )}
      <div
        className={`flex items-center  content-end flex-wrap gap-[26px]  ${
          !document && "min-h-[310px]"
        }`}
      >
        <input
          type="text"
          placeholder="Input your discussion name here..."
          className="bg-Grey-800 py-[14px] text-center w-full lg:w-[372px]"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <Button
          text={loader ? "Starting ..." : "Start Conversation"}
          color="text-Black-800"
          bg="bg-Mist-600"
          cta={create}
          disabled={loader}
          className="rounded-[10px] px-[10px] flex-shrink-0 flex justify-center p-small !font-normal Atlata"
        />
      </div>
    </div>
  );
};

export default Index;
