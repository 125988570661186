import { configureStore } from "@reduxjs/toolkit";

import docs from "./reducers/libreSiftReducer/uploadDocsReducer";
import conversations from "./reducers/common/";

export const store = configureStore({
  reducer: {
    docs,
    conversations
  },
});
